<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click.native="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-text-field
        hide-details
        flat
        :autofocus=true
        label="Search"
        prepend-inner-icon="mdi-magnify"
        v-model="keyword"
        @input="doSearch"
      ></v-text-field>
    </v-toolbar>
    <v-card-text>
      <v-subheader v-if="keyword.length > 0">
        Result search "{{ keyword }}"
      </v-subheader>
      <v-alert :value="products.length == 0 && keyword.length > 0" color="warning">
        Sorry, but no results were found.
      </v-alert>
      <!-- Hasil pencarian ditampilkan di sini -->
      <v-container class="ma-0 pa-0" grid-list-sm>
        <v-layout wrap>
          <v-flex v-for="product in products" :key="`product-` + product.id" xs6 sm6 md3 lg3 class="pa-1 pa-md-2 pa-lg-2">
            <book-item :product="product" @click.native="close"/>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "search",
  components: {
    BookItem: () =>
      import(/* webpackChunkName: "book-item" */ "@/components/BookItem.vue"),
  },
  data() {
    return {
      keyword: "",
      products: [],
    };
  },
  methods: {
    doSearch() {
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/filter/keywords/" + keyword)
          .then((response) => {
            let { data } = response.data;
            this.products = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.products = [];
      }
    },
    close() {
      this.$emit("closed", false);
    },
  },
};
</script>